import { useCallback, useMemo, useState } from 'react';

export const useMultiSelect = <T>(key: keyof T, list: T[]) => {
  const [selectedKeyList, setSelectedKeyList] = useState<React.Key[]>();
  const [selectedList, setSelectedList] = useState<T[]>();

  const onMultiSelectClick = useCallback(
    (newSelectedRowKeys: React.Key[]) => {
      setSelectedKeyList(newSelectedRowKeys);
      setSelectedList(list.filter((item) => newSelectedRowKeys.includes(item[key] as string)));
    },
    [list, setSelectedKeyList, setSelectedList]
  );

  return { selectedKeyList, selectedList, onMultiSelectClick };
};
