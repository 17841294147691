import { DropboxOutlined, NotificationOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, Modal, Table } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { z } from 'zod';

import * as API from '../../api';
import { DatePicker, DraggableModal, Input, InputNumber, Select, Toolbar, ToolbarLeft, ToolbarRight } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useForm, useSingleSelect, useWindowSize } from '../../hooks';
import { EVET_HAYIR, ROL, SiparisModel } from '../../types';
import { createOrderColumnsSalesStep2 } from '../../utils';

const FilterForm = z.object({
  urunId: z.number().optional(),
});

const IlanForm = z.object({
  pazaryeriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
});

export const SalesStep2 = () => {
  const kullaniciModel = useContext(UserContext);
  const { sm } = useWindowSize();
  const [modal, contextHolder] = Modal.useModal();

  const fetchModel = {
    firmaModel: kullaniciModel.firmaModel,
    kullaniciModel,
  } as SiparisModel;

  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [markets = []] = API.MARKETS.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [list = [], loading, getList] = API.ORDERS.useFetchIlan({});
  const { selected, setSelected, onSelectClick } = useSingleSelect('siparisId', list);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useForm(IlanForm, {
    pazaryeriId: undefined,
  });

  const formFilter = useForm(FilterForm, {
    urunId: undefined,
  });

  const get = useCallback(() => {
    const { data, success } = formFilter.parse();
    if (success) {
      getList({
        ...fetchModel,
        urunModel: {
          urunId: data.urunId,
        },
      });
    }
  }, [formFilter]);

  useEffect(() => {
    get();
  }, [formFilter.value?.urunId]);

  const handleOk = useCallback(async () => {
    const { data, success } = form.parse();
    if (success) {
      const postOrder = {
        ...selected,
        satisPazaryeriModel: {
          pazaryeriId: data.pazaryeriId,
        },
      } as SiparisModel;

      await API.ORDERS.sipIlan(postOrder);

      get();
      handleCancel();
    }
  }, [form, selected, setIsModalOpen]);

  const handleCancel = useCallback(() => {
    form.reset();
    setIsModalOpen(false);
  }, [form, setIsModalOpen]);

  const beforeOpen = useCallback(() => {
    if(selected?.alisFaturasiOnaylandi === EVET_HAYIR.EVET) {
      setIsModalOpen(true);
    } else {
      modal.confirm({
        title: 'Bu ürünün faturası onaylanmamış veya tedarikçi tarafından yüklenmemiş! Yine de satış açmak istiyor musunuz?',
        okText: 'Evet',
        cancelText: 'Hayır',
        onOk: () => {
          setIsModalOpen(true);
        }
      })
    }
  }, [selected, setIsModalOpen]);

  const columns = createOrderColumnsSalesStep2(get);

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft classname="flex-1">
          <label className="whitespace-nowrap">Ürün :</label>
          <Select className="w-full sm:w-72" options={products} fieldNames={{ label: 'urunAdi', value: 'urunId' }} form={formFilter} searchFields={['barkodNo']} placeholder="Ürün Adı veya Barkod" name="urunId" />
        </ToolbarLeft>
        <ToolbarRight>
          <Button type="primary" disabled={!Boolean(selected)} onClick={beforeOpen} icon={<RetweetOutlined />}>
            İlan Gir
          </Button>
        </ToolbarRight>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={false}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
        onRow={(record) => ({
          onClick: () => setSelected(record),
        })}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: selected?.siparisId ? [selected.siparisId] : [],
          onChange: onSelectClick,
        }}
      />

      <DraggableModal width={300} title="İlan Giriş" okText="Kaydet" cancelText="Kapat" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="flex flex-col">
          <label className="whitespace-nowrap">Satış Pazaryeri :</label>
          <Select options={markets} fieldNames={{ label: 'pazaryeriAdi', value: 'pazaryeriId' }} form={form} name="pazaryeriId" />
        </div>
      </DraggableModal>
      {contextHolder}
    </div>
  );
};

SalesStep2.path = 'sales-step-2';
SalesStep2.title = 'İlan Giriş';
SalesStep2.group = 'sales';
SalesStep2.roles = [ROL.ADMIN, ROL.DEPOCU];
SalesStep2.icon = <NotificationOutlined />;
