import { ClockCircleOutlined, ContainerOutlined } from '@ant-design/icons';
import { Card, Statistic, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useContext, useMemo } from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';

import * as API from '../api';
import { UserContext } from '../contexts/UserContext';
import { TedarikciDashboardModel } from '../types';
import { moneyColumnRender } from '../utils';
import { COLORS } from '../utils/chart';

const emptyDashboard: TedarikciDashboardModel = {
  faturaDashboardModel: { faturasiGelenSiparisSayisi: 0, faturasiGelmeyenSiparisSayisi: 0, faturasiOnaylanmayanSiparisSayisi: 0 },
  gelirGiderKarModel: {
    gelirToplam: 0,
    giderToplam: 0,
    kargodaSiparisSayisi: 0,
    karToplam: 0,
    odemeBeklenenSiparisSayisi: 0,
  },
};

export const TedHome = () => {
  const kullaniciModel = useContext(UserContext);

  const [dashboardModel, loading] = API.DASHBOARD.useFetchTedarikci({ init: true, initBody: kullaniciModel });

  const { faturaDashboardModel, gelirGiderKarModel } = dashboardModel || emptyDashboard;

  return (
    <div className="flex flex-row gap-4 flex-wrap">
      <div className="flex flex-wrap flex-col gap-1.5 w-full max-h-[31.5rem] ">
        <Card className="h-24" bordered={false}>
          <Statistic title="Gelir" value={gelirGiderKarModel.gelirToplam} valueStyle={{ color: '#3f8600' }} prefix={<ContainerOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic title="Gider" value={gelirGiderKarModel.giderToplam} valueStyle={{ color: '#3f8600' }} prefix={<ContainerOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic title="Kar" value={gelirGiderKarModel.karToplam} valueStyle={{ color: '#3f8600' }} prefix={<ContainerOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic title="Kargodaki Sipariş" value={gelirGiderKarModel.kargodaSiparisSayisi} valueStyle={{ color: '#3f8600' }} prefix={<ContainerOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic title="Ödeme Bekleyen Sipariş" value={gelirGiderKarModel.odemeBeklenenSiparisSayisi} valueStyle={{ color: '#3f8600' }} prefix={<ContainerOutlined />} />
        </Card>

        <Card className="h-24" bordered={false}>
          <Statistic title="Faturası Yüklenenler" value={faturaDashboardModel.faturasiGelenSiparisSayisi} valueStyle={{ color: '#3f8600' }} prefix={<ContainerOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic
            title="Faturası Yüklenmeyenler"
            value={faturaDashboardModel.faturasiGelmeyenSiparisSayisi}
            valueStyle={{ color: '#cf1322' }}
            prefix={<ClockCircleOutlined />}
          />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic
            title="Faturası Onay Bekleyenler"
            value={faturaDashboardModel.faturasiOnaylanmayanSiparisSayisi}
            valueStyle={{ color: '#cf1322' }}
            prefix={<ClockCircleOutlined />}
          />
        </Card>
      </div>
    </div>
  );
};
