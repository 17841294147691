import { LaptopOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { z } from 'zod';

import * as API from '../../api';
import { DatePicker, DictFormActions, Input, InputNumber, Select } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm } from '../../hooks';
import { ROL } from '../../types';

const OrderForm = z.object({
  kullaniciId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  pazaryeriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  kategoriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  urunId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  depoyeriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  adet: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).min(1, "Adet 1'den büyük olmalı."),
  alisFiyat: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).gt(0, "Fiyat 0'dan büyük olmalı."),
  siparisNo: z.string().min(1, 'Zorunludur'),
  siparisTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  tedarikciAciklama: z.string().optional(),
});

export const NewOrder = ({ onSaveDone = () => null } : { onSaveDone? : CallableFunction}) => {
  const kullaniciModel = useContext(UserContext);

  const [users = [], _, getUsers] = API.USERS.useFetchTedarikci({});
  const [markets = []] = API.MARKETS.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [categories = []] = API.CATEGORY.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [warehouses = []] = API.WAREHOUSES.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });

  const { form, clear } = useFillForm(OrderForm, {
    kullaniciId: kullaniciModel.kullaniciTipi === ROL.ADMIN ? undefined : kullaniciModel.kullaniciId,
    pazaryeriId: undefined,
    kategoriId: undefined,
    urunId: undefined,
    depoyeriId: undefined,
    adet: undefined,
    alisFiyat: undefined,
    siparisNo: '',
    siparisTarihi: new Date(),
    tedarikciAciklama: '',
  });

  useEffect(() => {
    if (kullaniciModel.kullaniciTipi === ROL.ADMIN) {
      getUsers(kullaniciModel.firmaModel);
    }
  }, []);

  const productByCategory = useMemo(() => {
    return products.filter((product) => product.kategoriModel?.kategoriId === form.value.kategoriId);
  }, [form.value.kategoriId]);

  const save = useCallback(async () => {
    const { success, data } = form.parse();
    if (success) {
      const formedData = {
        pazaryeriModel: {
          pazaryeriId: data.pazaryeriId,
        },
        kategoriModel: {
          kategoriId: data.kategoriId,
        },
        urunModel: {
          urunId: data.urunId,
        },
        depoyeriModel: {
          depoyeriId: data.depoyeriId,
        },
        adet: data.adet,
        alisFiyat: data.alisFiyat,
        siparisNo: data.siparisNo,
        siparisTarihi: data.siparisTarihi.toISOString(),
        tedarikciAciklama: data.tedarikciAciklama,
        firmaModel: kullaniciModel.firmaModel,
        kullaniciModel: {
          kullaniciId: data.kullaniciId,
        },
      };
      await API.ORDERS.save(formedData);

      onSaveDone();
      clear();
    } else {
      form.setAllTouched();
    }
  }, [form]);

  return (
    <div className="flex flex-col gap-2">
      <Card title="Yeni Sipariş Ekleme">
        <div className="grid grid-cols-[100px_1fr] items-center gap-y-6 gap-x-4 sm:max-w-sm ">
          {kullaniciModel.kullaniciTipi === ROL.ADMIN && (
            <>
              <label className="whitespace-nowrap">Tedarikçi :</label>
              <Select options={users} fieldNames={{ label: 'kullaniciAdi', value: 'kullaniciId' }} form={form} name="kullaniciId" />
            </>
          )}

          <label className="whitespace-nowrap">Pazaryeri :</label>
          <Select options={markets} fieldNames={{ label: 'pazaryeriAdi', value: 'pazaryeriId' }} form={form} name="pazaryeriId" />

          <label className="whitespace-nowrap">Kategori :</label>
          <Select options={categories} fieldNames={{ label: 'kategoriAdi', value: 'kategoriId' }} form={form} name="kategoriId" />

          <label className="whitespace-nowrap">Ürün :</label>
          <Select options={productByCategory} fieldNames={{ label: 'urunAdi', value: 'urunId' }} searchFields={['barkodNo']} placeholder="Ürün Adı veya Barkod" form={form} name="urunId" />

          <label className="whitespace-nowrap">Depoyeri :</label>
          <Select options={warehouses} fieldNames={{ label: 'depoyeriAdi', value: 'depoyeriId' }} form={form} name="depoyeriId" />

          <label className="whitespace-nowrap">Adet :</label>
          <InputNumber form={form} name="adet" min={1} />

          <label className="whitespace-nowrap">Alış Fiyat :</label>
          <InputNumber form={form} name="alisFiyat" min={0} />

          <label className="whitespace-nowrap">Sipariş No :</label>
          <Input form={form} name="siparisNo" />

          <label className="whitespace-nowrap">Sipariş Tarihi :</label>
          <DatePicker form={form} name="siparisTarihi" />

          <label className="whitespace-nowrap">Açiklama :</label>
          <Input form={form} name="tedarikciAciklama" />

          <DictFormActions save={save} clear={clear} />
        </div>
      </Card>
    </div>
  );
};

NewOrder.path = 'new-order';
NewOrder.title = 'Yeni Sipariş';
NewOrder.group = 'supplier';
NewOrder.roles = [ROL.TEDARIKCI, ROL.ADMIN];
NewOrder.icon = <PlusCircleOutlined />;
