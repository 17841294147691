import { CheckCircleOutlined, CloseCircleOutlined, CloudDownloadOutlined, FieldTimeOutlined, FileSyncOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import React, { useCallback, useContext, useState } from 'react';

import * as API from '../../api';
import { DocViewer, Toolbar, ToolbarLeft, ToolbarRight } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useSingleSelect, useWindowSize } from '../../hooks';
import { DokumanModel, ROL, SIPARIS_STATU } from '../../types';
import { createOrderColumns } from '../../utils';

export const BillStep2 = () => {
  const kullaniciModel = useContext(UserContext);
  const { sm } = useWindowSize();

  const [list = [], loading, getList] = API.ORDERS.useFetchAlisFaturasiOnayBekleyenler({ init: true, initBody: kullaniciModel.firmaModel });
  const { selected, setSelected, onSelectClick } = useSingleSelect('siparisId', list);
  const [doc, setDoc] = useState<DokumanModel>();
  const [byteArray, setByteArray] = useState<string>();

  const approve = useCallback(async () => {
    if (selected?.siparisId) {
      await API.FILES.approve(selected?.siparisId);

      getList(kullaniciModel.firmaModel);
    }
  }, [selected]);

  const reject = useCallback(async () => {
    if (selected?.siparisId) {
      await API.FILES.reject(selected?.siparisId);

      getList(kullaniciModel.firmaModel);
    }
  }, [selected]);

  const downloadFile = useCallback(async () => {
    if (selected?.alisFaturasiModel) {
      await API.FILES.download(selected.alisFaturasiModel);
    }
  }, [selected, setByteArray]);

  const columns = createOrderColumns(SIPARIS_STATU.SIP_KARGO, kullaniciModel.kullaniciTipi === ROL.ADMIN);

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft>
          <Button onClick={() => getList(kullaniciModel.firmaModel)} icon={<RetweetOutlined />}>
            Yenile
          </Button>
        </ToolbarLeft>
        <ToolbarRight classname="sm:flex-none">
          <Button className="w-full" type="primary" disabled={!Boolean(selected)} onClick={approve} icon={<CheckCircleOutlined />}>
            Onayla
          </Button>
          <Button className="w-full" type="primary" danger disabled={!Boolean(selected)} onClick={reject} icon={<CloseCircleOutlined />}>
            Reddet
          </Button>
          <Button className="w-full" disabled={!Boolean(selected)} onClick={downloadFile} icon={<CloudDownloadOutlined />}>
            Fatura İndir
          </Button>
        </ToolbarRight>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={false}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
        onRow={(record) => ({
          onClick: () => setSelected(record),
        })}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: selected?.siparisId ? [selected.siparisId] : [],
          onChange: onSelectClick,
        }}
      />
    </div>
  );
};

BillStep2.path = 'bill-step-2';
BillStep2.title = 'Onay Bekleyenler';
BillStep2.group = 'bills';
BillStep2.roles = [ROL.ADMIN, ROL.DEPOCU];
BillStep2.icon = <FileSyncOutlined />;
