import { useFetch } from '../hooks';
import { SiparisModel, UseFetchOptions } from '../types';
import { request } from '../utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<SiparisModel[]>('/siparis/list-by-firma', { method: 'POST', ...config }),
  useFetchStatu: (config: UseFetchOptions) => useFetch<SiparisModel[]>('/siparis/aktif-list-by-firma-and-kullanici-and-statu', { method: 'POST', ...config }),
  useFetchPayment: (config: UseFetchOptions) => useFetch<SiparisModel[]>('/siparis/tedarikci/odeme-list', { method: 'POST', ...config }),
  useFetchWarehouse: (config: UseFetchOptions) => useFetch<SiparisModel[]>('/siparis/aktif-list-for-depo-giris', { method: 'POST', ...config }),
  useFetchIlan: (config: UseFetchOptions) => useFetch<SiparisModel[]>('/siparis/aktif-list-for-ilan-giris', { method: 'POST', ...config }),
  useFetchSatis: (config: UseFetchOptions) => useFetch<SiparisModel[]>('/siparis/aktif-list-for-ilan-satis', { method: 'POST', ...config }),
  useFetchSatisKargo: (config: UseFetchOptions) => useFetch<SiparisModel[]>('/siparis/aktif-list-for-satis-kargo', { method: 'POST', ...config }),
  useFetchSatisKargolanmis: (config: UseFetchOptions) => useFetch<SiparisModel[]>('/siparis/aktif-list-for-satis-kargolanmis', { method: 'POST', ...config }),
  useFetchAdminOdenecekList: (config: UseFetchOptions) => useFetch<SiparisModel[]>('/siparis/admin/odenecek-list', { method: 'POST', ...config }),
  useFetchAlisFaturasiBekleyenler: (config: UseFetchOptions) => useFetch<SiparisModel[]>('/siparis/tedarikci/alis-faturasi-bekleyenler', { method: 'POST', ...config }),
  useFetchAlisFaturasiOnayBekleyenler: (config: UseFetchOptions) => useFetch<SiparisModel[]>('/siparis/depocu/alis-faturasi-onay-bekleyenler', { method: 'POST', ...config }),
  useFetchSatisFaturasiBekleyenler: (config: UseFetchOptions) => useFetch<SiparisModel[]>('/siparis/depocu/satis-faturasi-bekleyenler', { method: 'POST', ...config }),
  useFetchAlisFaturasiByUrun: (config: UseFetchOptions) => useFetch<SiparisModel[]>('/siparis/get-alis-fatura-list-by-urun', { method: 'POST', ...config }),
  activePassive: async (order: SiparisModel) => request('/siparis/aktif-pasif', 'POST', order),
  activePassiveToplu: async (orders: SiparisModel[]) => request('/siparis/aktif-pasif-toplu', 'POST', orders),
  save: async (order: SiparisModel) => request('/siparis/tedarikci/kayit', 'POST', order),
  update: async (order: SiparisModel) => request('/siparis-guncelleme/tedarikci/guncelleme', 'POST', order),
  sipOnay: async (order: SiparisModel) => request('/siparis/tedarikci/siparis-onay', 'POST', order),
  sipOnayToplu: async (orders: SiparisModel[]) => request('/siparis/tedarikci/siparis-onay-toplu', 'POST', orders),
  sipKargo: async (order: SiparisModel) => request('/siparis/tedarikci/siparis-kargo', 'POST', order),
  sipDepo: async (order: SiparisModel) => request('/siparis/depocu/siparis-depo', 'POST', order),
  sipIlan: async (order: SiparisModel) => request('/siparis/depocu/siparis-ilan', 'POST', order),
  sipIlanIptal: async (order: SiparisModel) => request('/siparis/depocu/siparis-ilan-iptal', 'POST', order),
  sipSatis: async (order: SiparisModel) => request('/siparis/depocu/siparis-satis', 'POST', order),
  sipSatisKargo: async (order: SiparisModel) => request('/siparis/depocu/satis-kargo', 'POST', order),
  sipTedarikciOdeme: async (order: SiparisModel) => request('/siparis/depocu/tedarikci-odeme', 'POST', order),
};
