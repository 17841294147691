import { GiftOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, DatePicker as DatePickerAnt, Modal, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/tr_TR';
import dayjs from 'dayjs';
import React, { useCallback, useContext, useState } from 'react';
import { z } from 'zod';

import * as API from '../../api';
import { DatePicker, DraggableModal, Input, Select, Toolbar, ToolbarLeft } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useForm, useSingleSelect, useWindowSize } from '../../hooks';
import { ROL, SiparisModel } from '../../types';
import { createOrderColumnsSalesStep4, createOrderColumnsSalesStep5 } from '../../utils';

const FilterForm = z.object({
  pazaryeriId: z.number().optional(),
  urunId: z.number().optional(),
});

const CargoForm = z.object({
  kargoFirmasiId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  satisKargoNo: z.string({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).min(1),
  satisKargoTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
});

export const SalesStep5 = () => {
  const kullaniciModel = useContext(UserContext);
  const { sm } = useWindowSize();

  const fetchModel = {
    firmaModel: kullaniciModel.firmaModel,
    kullaniciModel,
  } as SiparisModel;

  const [cargos = []] = API.CARGOS.useFetch({ init: true });
  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [markets = []] = API.MARKETS.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [list = [], loading, getList] = API.ORDERS.useFetchSatisKargolanmis({});
  const { selected, setSelected, onSelectClick } = useSingleSelect('siparisId', list);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [date, setDate] = useState<dayjs.Dayjs | null>(dayjs(new Date()));
  const form = useForm(CargoForm, {
    kargoFirmasiId: undefined,
    satisKargoNo: '',
    satisKargoTarihi: new Date(),
  });

  const formFilter = useForm(FilterForm, {
    pazaryeriId: undefined,
    urunId: undefined,
  });

  const get = useCallback(() => {
    const { data, success} = formFilter.parse();
    if(success && date) {
      const lastDay = date.daysInMonth();

      const satisKargoTarihiStart = date.date(1).toISOString();
      const satisKargoTarihiEnd = date.date(lastDay).toISOString();

      getList({
        ...fetchModel,
        satisKargoTarihiStart,
        satisKargoTarihiEnd,
        satisPazaryeriModel: {
          pazaryeriId: data.pazaryeriId,
        },
        urunModel: {
          urunId: data.urunId,
        },
      });
    }
   
  }, [formFilter, date]);

  const handleOk = useCallback(async () => {
    const { data, success } = form.parse();
    if (success) {
      const postOrder = {
        ...selected,
        satisKargoFirmasiModel: {
          kargoFirmasiId: data.kargoFirmasiId,
        },
        satisKargoNo: data.satisKargoNo,
        satisKargoTarihi: data.satisKargoTarihi.toISOString(),
      } as SiparisModel;

      await API.ORDERS.sipSatisKargo(postOrder);

      get();
      handleCancel();
    }
  }, [form, selected, setIsModalOpen]);

  const handleCancel = useCallback(() => {
    form.reset();
    setIsModalOpen(false);
  }, [form, setIsModalOpen]);

  const columns = createOrderColumnsSalesStep5(get);

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft>
          <label className="whitespace-nowrap">Satış Pazaryeri :</label>
          <Select className="w-full sm:w-40" options={markets} fieldNames={{ label: 'pazaryeriAdi', value: 'pazaryeriId' }} form={formFilter} name="pazaryeriId" />

          <label className="whitespace-nowrap">Ürün :</label>
          <Select className="sm:w-72" options={products} fieldNames={{ label: 'urunAdi', value: 'urunId' }} form={formFilter} searchFields={['barkodNo']} placeholder="Ürün Adı veya Barkod" name="urunId" />

          <DatePickerAnt value={date} onChange={(value) => setDate(value)} allowClear={false} className="w-full sm:w-36" picker="month" locale={locale} format="YYYY MMMM" />

          <Button onClick={() => get()} icon={<RetweetOutlined />}>
            Listele
          </Button>
        </ToolbarLeft>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={false}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
        onRow={(record) => ({
          onClick: () => setSelected(record),
        })}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: selected?.siparisId ? [selected.siparisId] : [],
          onChange: onSelectClick,
        }}
      />

      <DraggableModal width={300} title="Satış Kargo" okText="Kaydet" cancelText="Kapat" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="flex flex-col">
          <label className="whitespace-nowrap">Kargo Firması :</label>
          <Select options={cargos} fieldNames={{ label: 'kargoFirmasiAdi', value: 'kargoFirmasiId' }} form={form} name="kargoFirmasiId" />

          <label className="whitespace-nowrap">Kargo No :</label>
          <Input form={form} name="satisKargoNo" />

          <label className="whitespace-nowrap">Kargo Tarihi :</label>
          <DatePicker form={form} name="satisKargoTarihi" />
        </div>
      </DraggableModal>
    </div>
  );
};

SalesStep5.path = 'sales-step-5';
SalesStep5.title = 'Satış Kargolanmış';
SalesStep5.group = 'sales';
SalesStep5.roles = [ROL.ADMIN, ROL.DEPOCU];
SalesStep5.icon = <GiftOutlined />;
