// handle border collapse
function compactItemBorder(token, options) {
  const childCombinator = options.borderElCls ? '> *' : '';
  const hoverEffects = ['hover', options.focus ? 'focus' : null, 'active'].filter(Boolean).map(n => `&:${n} ${childCombinator}`).join(',');
  return {
    '&-item:not(&-last-item)': {
      marginInlineEnd: -token.lineWidth
    },
    '&-item': Object.assign(Object.assign({
      [hoverEffects]: {
        zIndex: 2
      }
    }, options.focusElCls ? {
      [`&${options.focusElCls}`]: {
        zIndex: 2
      }
    } : {}), {
      [`&[disabled] ${childCombinator}`]: {
        zIndex: 0
      }
    })
  };
}
// handle border-radius
function compactItemBorderRadius(prefixCls, options) {
  const childCombinator = options.borderElCls ? `> ${options.borderElCls}` : '';
  return {
    [`&-item:not(&-first-item):not(&-last-item) ${childCombinator}`]: {
      borderRadius: 0
    },
    '&-item:not(&-last-item)&-first-item': {
      [`& ${childCombinator}, &${prefixCls}-sm ${childCombinator}, &${prefixCls}-lg ${childCombinator}`]: {
        borderStartEndRadius: 0,
        borderEndEndRadius: 0
      }
    },
    '&-item:not(&-first-item)&-last-item': {
      [`& ${childCombinator}, &${prefixCls}-sm ${childCombinator}, &${prefixCls}-lg ${childCombinator}`]: {
        borderStartStartRadius: 0,
        borderEndStartRadius: 0
      }
    }
  };
}
export function genCompactItemStyle(token) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    focus: true
  };
  return {
    [`${token.componentCls}-compact`]: Object.assign(Object.assign({}, compactItemBorder(token, options)), compactItemBorderRadius(token.componentCls, options))
  };
}