import showWaveEffect from './WaveEffect';
export default function useWave(nodeRef, className) {
  function showWave() {
    var _a;
    const node = nodeRef.current;
    // Skip if not exist doc
    const container = ((_a = node.getRootNode) === null || _a === void 0 ? void 0 : _a.call(node)) || (node === null || node === void 0 ? void 0 : node.ownerDocument);
    if (container) {
      showWaveEffect(container, node, className);
    }
  }
  return showWave;
}