import { useFetch } from '../hooks';
import { PazaryeriModel, UseFetchOptions } from '../types';
import { request } from '../utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<PazaryeriModel[]>('/pazaryeri/list-by-firma', { method: 'POST', ...config }),
  useFetchActive: (config: UseFetchOptions) => useFetch<PazaryeriModel[]>('/pazaryeri/aktif-list-by-firma', { method: 'POST', ...config }),
  save: async (market: PazaryeriModel) => request('/pazaryeri/kayit', 'POST', market),
  update: async (market: PazaryeriModel) => request('/pazaryeri/guncelle', 'POST', market),
  activePassive: async (market: PazaryeriModel) => request('/pazaryeri/aktif-pasif', 'POST', market),
};
