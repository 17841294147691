import { FileTextOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, DatePicker as DatePickerAnt, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/tr_TR';
import dayjs from 'dayjs';
import React, { useCallback, useContext, useState } from 'react';

import { z } from 'zod';

import * as API from '../../api';
import { Select, Toolbar, ToolbarLeft } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useForm, useWindowSize } from '../../hooks';
import { ROL, SiparisModel, SIPARIS_STATU } from '../../types';
import { createOrderColumns, createOrderColumnsBillStep4 } from '../../utils';

const FilterForm = z.object({
  urunId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
});

export const BillStep4 = () => {
  const kullaniciModel = useContext(UserContext);
  const { sm } = useWindowSize();

  const fetchModel = {
    firmaModel: kullaniciModel.firmaModel,
    kullaniciModel,
  } as SiparisModel;

  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [list = [], loading, getList] = API.ORDERS.useFetchAlisFaturasiByUrun({});
  const [date, setDate] = useState<dayjs.Dayjs | null>(dayjs(new Date()));

  const formFilter = useForm(FilterForm, {
    urunId: undefined,
  });

  const get = useCallback(() => {
    const { data, success } = formFilter.parse();
    if (success && date) {
      const lastDay = date.daysInMonth();

      const siparisTarihiStart = date.date(1).toISOString();
      const siparisTarihiEnd = date.date(lastDay).toISOString();

      getList({
        ...fetchModel,
        siparisTarihiStart,
        siparisTarihiEnd,
        urunModel: {
          urunId: data.urunId,
        },
      });
    }
  }, [formFilter, date]);

  const downloadFile = useCallback(async (order: SiparisModel) => {
    if (order?.alisFaturasiModel) {
      API.FILES.download(order.alisFaturasiModel);
    }
  }, []);

  const columns = createOrderColumnsBillStep4(downloadFile);

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft>
          <label className="whitespace-nowrap">Ürün :</label>
          <Select className="sm:w-72" options={products} fieldNames={{ label: 'urunAdi', value: 'urunId' }} form={formFilter} searchFields={['barkodNo']} placeholder="Ürün Adı veya Barkod" name="urunId" />
          <DatePickerAnt value={date} onChange={(value) => setDate(value)} allowClear={false} className="w-full sm:w-36" picker="month" locale={locale} format="YYYY MMMM" />
          <Button onClick={get} icon={<RetweetOutlined />}>
            Yenile
          </Button>
        </ToolbarLeft>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={false}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
      />
    </div>
  );
};

BillStep4.path = 'bill-step-4';
BillStep4.title = 'Alış Fatura';
BillStep4.group = 'bills';
BillStep4.roles = [ROL.ADMIN, ROL.DEPOCU];
BillStep4.icon = <FileTextOutlined />;
