import { CheckCircleOutlined } from '@ant-design/icons';
import { DatePicker, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/tr_TR';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';

import * as API from '../../api';
import { Toolbar, ToolbarLeft } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useWindowSize } from '../../hooks';
import { EVET_HAYIR, ROL, SiparisModel } from '../../types';
import { createOrderColumnsPaymentStep2 } from '../../utils';

export const PaymentStep2 = () => {
  const kullaniciModel = useContext(UserContext);

  const { sm } = useWindowSize();
  const [date, setDate] = useState<dayjs.Dayjs | null>(dayjs(new Date()));
  const [list = [], loading, getList] = API.ORDERS.useFetchPayment({});

  const fetchModel = {
    firmaModel: kullaniciModel.firmaModel,
    kullaniciModel,
    odemesiYapildi: EVET_HAYIR.EVET,
  } as SiparisModel;

  useEffect(() => {
    if (date) {
      const lastDay = date.daysInMonth();

      const siparisTarihiStart = date.date(1).toISOString();
      const siparisTarihiEnd = date.date(lastDay).toISOString();

      getList({
        ...fetchModel,
        siparisTarihiStart,
        siparisTarihiEnd,
      });
    }
  }, [date]);

  const columns = createOrderColumnsPaymentStep2();

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft classname="flex-1">
          <DatePicker value={date} onChange={(value) => setDate(value)} className="w-full sm:w-36" picker="month" locale={locale} format="YYYY MMMM" />
        </ToolbarLeft>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={false}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
      />
    </div>
  );
};

PaymentStep2.path = 'payment-step-2';
PaymentStep2.title = 'Ödeme Yapılanlar';
PaymentStep2.group = 'payment';
PaymentStep2.roles = [ROL.TEDARIKCI];
PaymentStep2.icon = <CheckCircleOutlined />;
