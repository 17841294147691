import React, { useCallback } from 'react';
import { Select as SelectAnt } from 'antd';
import { DefaultOptionType } from 'antd/es/select';

type Props = {
  form: any;
  name: string;
  options: any[];
  fieldNames: { value: string; label: string };
  disabled?: boolean;
  className?: string;
  placeholder? : string;
  searchFields? : string[];
};

export const Select = ({ form, name, options, fieldNames, disabled, className, placeholder = 'Seçiniz', searchFields = [] }: Props) => {
  const filterFn = useCallback((input :string, option : DefaultOptionType | undefined) => {
    if(option) {

      const _searchFields = [fieldNames.label, ...searchFields]
      const searchText = _searchFields.map(key => option[key]).join('#')
  
      return searchText.toLowerCase().includes(input.toLowerCase())
    }
    return false;
  }, [fieldNames, searchFields])

  return (
    <div className={`relative ${className}`}>
      <SelectAnt
        className="w-full md:max-w-[300px]"
        showSearch
        placeholder={placeholder}
        options={options}
        fieldNames={fieldNames}
        filterOption={filterFn}
        allowClear
        disabled={disabled}
        {...form.props(name)}
      ></SelectAnt>
      {form.toucheds[name] && <small className="left-0 -bottom-4 text-red-500">{form.errors[name]}</small>}
    </div>
  );
};
