import { useCallback, useEffect, useState } from 'react';
import { UseFetchOptions } from '../types';
import { request } from '../utils';
import { objToHashSymbol } from '../utils/hash';

const defaultOptions: UseFetchOptions = {
  init: false,
  method: 'GET',
  initBody: null,
  initParams: null,
};

const cacheUrls = [
  '/pazaryeri/aktif-list-by-firma',
  '/kategori/aktif-list-by-firma',
  '/urun/aktif-list-by-firma',
  '/depoyeri/aktif-list-by-firma',
  '/kargo-firmasi/list',
];

type RequestCache = {
  time: number;
  body: any;
  params: any;
  data: any;
};

const cached: Record<symbol, RequestCache> = {};

export const useFetch = <T>(url: string, options = defaultOptions): [T | undefined, boolean, CallableFunction, any] => {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const requestCallback = useCallback(
    async (data: any, params: any) => {
      setLoading(true);

      const response = await request(url, options.method, data, params, options, true);

      setLoading(false);
      if (response.ok) {
        cached[Symbol.for(url)] = {
          time: new Date().getTime(),
          body: objToHashSymbol(data),
          params: objToHashSymbol(params),
          data: response.data,
        };
        setData(response.data);
      } else {
        setData(undefined);
        setError(response.error);
      }
    },
    [url, setData, setLoading, setError]
  );

  useEffect(() => {
    if (options.init) {
      const cache = cached[Symbol.for(url)];
      
      if (cache && cacheUrls.includes(url) && cache.body === objToHashSymbol(options.initBody) && cache.params === objToHashSymbol(options.initParams) ) {
        setData(cache.data);
      } else {
        requestCallback(options.initBody, options.initParams);
      }
    }
  }, []);

  return [data, loading, requestCallback, error];
};
