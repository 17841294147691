import { DownloadOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, Modal, Table } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { z } from 'zod';

import * as API from '../../api';
import { DatePicker, DraggableModal, Input, InputNumber, Select, Toolbar, ToolbarLeft, ToolbarRight } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useForm, useSingleSelect, useWindowSize } from '../../hooks';
import { ROL, SiparisModel } from '../../types';
import { createOrderColumnsSalesStep1 } from '../../utils';

const FilterForm = z.object({
  urunId: z.number().optional(),
  siparisNo: z.string().optional(),
  kargoNo: z.string().optional(),
});

const WarehouseForm = z.object({
  tedarikciOdenecekFiyat: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  tedarikciOdenecekTarih: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  seriNo: z.string().min(1, 'Zorunludur'),
  siparisDepoGirisTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
});

export const SalesStep1 = () => {
  const kullaniciModel = useContext(UserContext);
  const { sm } = useWindowSize();

  const fetchModel = {
    firmaModel: kullaniciModel.firmaModel,
    kullaniciModel,
  } as SiparisModel;

  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [list = [], loading, getList] = API.ORDERS.useFetchWarehouse({});
  const { selected, setSelected, onSelectClick } = useSingleSelect('siparisId', list);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useForm(WarehouseForm, {
    tedarikciOdenecekFiyat: undefined,
    tedarikciOdenecekTarih: new Date(),
    seriNo: '',
    siparisDepoGirisTarihi: new Date(),
  });

  const formFilter = useForm(FilterForm, {
    urunId: undefined,
    siparisNo: '',
    kargoNo: '',
  });

  const get = useCallback(() => {
    const { data } = formFilter.parse();
    getList({
      ...fetchModel,
      siparisNo: data.siparisNo,
      kargoNo: data.kargoNo,
      urunModel: {
        urunId: data.urunId,
      },
    });
  }, [formFilter]);

  const handleOk = useCallback(async () => {
    const { data, success } = form.parse();
    if (success) {
      const postOrder = {
        ...selected,
        tedarikciOdenecekFiyat: data.tedarikciOdenecekFiyat,
        tedarikciOdenecekTarih: data.tedarikciOdenecekTarih.toISOString(),
        seriNo: data.seriNo,
        siparisDepoGirisTarihi: data.siparisDepoGirisTarihi.toISOString(),
      } as SiparisModel;

      await API.ORDERS.sipDepo(postOrder);

      get();
      handleCancel();
    }
  }, [form, selected, setIsModalOpen]);

  const handleCancel = useCallback(() => {
    form.reset();
    setIsModalOpen(false);
  }, [form, setIsModalOpen]);

  useEffect(() => {
    get()
  }, []);

  const columns = createOrderColumnsSalesStep1(get);

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft>
          <div className="flex gap-2 items-center">
            <label className="whitespace-nowrap">Ürün :</label>
            <Select className="sm:w-72" options={products} fieldNames={{ label: 'urunAdi', value: 'urunId' }} form={formFilter} searchFields={['barkodNo']} placeholder="Ürün Adı veya Barkod"  name="urunId" />

            <label className="whitespace-nowrap">Sipariş No :</label>
            <Input form={formFilter} name="siparisNo" />

            <label className="whitespace-nowrap">Kargo No :</label>
            <Input form={formFilter} name="kargoNo" />
          </div>

          <Button onClick={() => get()} icon={<RetweetOutlined />}>
            Listele
          </Button>
        </ToolbarLeft>
        <ToolbarRight>
          <Button type="primary" disabled={!Boolean(selected)} onClick={() => setIsModalOpen(true)} icon={<RetweetOutlined />}>
            Depo Girişi Yap
          </Button>
        </ToolbarRight>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={false}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
        onRow={(record) => ({
          onClick: () => setSelected(record),
        })}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: selected?.siparisId ? [selected.siparisId] : [],
          onChange: onSelectClick,
        }}
      />

      <DraggableModal width={300} title="Sipariş Kargo" okText="Kaydet" cancelText="Kapat" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="flex flex-col">
          <label className="whitespace-nowrap">Ödenecek Fiyat :</label>
          <InputNumber form={form} name="tedarikciOdenecekFiyat" min={1} />

          <label className="whitespace-nowrap">Ödenecek Tarih :</label>
          <DatePicker form={form} name="tedarikciOdenecekTarih" />

          <label className="whitespace-nowrap">Seri No / Imei No</label>
          <Input form={form} name="seriNo" />

          <label className="whitespace-nowrap">Depo Giriş Tarihi :</label>
          <DatePicker form={form} name="siparisDepoGirisTarihi" />
        </div>
      </DraggableModal>
    </div>
  );
};

SalesStep1.path = 'sales-step-1';
SalesStep1.title = 'Depo Giriş';
SalesStep1.group = 'sales';
SalesStep1.roles = [ROL.ADMIN, ROL.DEPOCU];
SalesStep1.icon = <DownloadOutlined />;
