import { UserOutlined } from '@ant-design/icons';
import { Collapse, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback, useContext } from 'react';
import { z } from 'zod';

import * as API from '../../api';
import { DictFormActions, DictTableActions, DictTableStatus, Input, Select } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm, useWindowSize } from '../../hooks';
import { AKTIF_PASIF, KullaniciModel, ROL } from '../../types';

const UserForm = z.object({
  kullaniciAdi: z.string().min(1, 'Zorunludur'),
  kullaniciSifre: z.string().min(1, 'Zorunludur'),
  ad: z.string().min(1, 'Zorunludur'),
  soyad: z.string().min(1, 'Zorunludur'),
  telefonNumarasi: z.string().min(1, 'Zorunludur'),
  kullaniciTipi: z.nativeEnum(ROL),
});

export const User = () => {
  const { sm } = useWindowSize();
  const { firmaModel } = useContext(UserContext);

  const [list = [], loading, getList] = API.USERS.useFetch({ init: true, initBody: firmaModel });
  const { form, selected, clear, fill } = useFillForm(UserForm, {
    kullaniciAdi: '',
    kullaniciSifre: '',
    ad: '',
    soyad: '',
    telefonNumarasi: '',
    kullaniciTipi: undefined,
  });

  const save = useCallback(async () => {
    const { success, data } = form.parse();
    console.log(success);
    if (success) {
      if (selected) {
        await API.USERS.update({
          ...data,
          kullaniciId: selected.kullaniciId,
          firmaModel,
        });
      } else {
        await API.USERS.save({
          ...data,
          firmaModel,
        });
      }

      clear();
      getList(firmaModel);
    } else {
      form.setAllTouched();
    }
  }, [form, selected]);

  const remove = useCallback(async (values) => {
    await API.USERS.activePassive({
      kullaniciId: values.kullaniciId,
      aktifPasif: values.aktifPasif === AKTIF_PASIF.AKTIF ? AKTIF_PASIF.PASIF : AKTIF_PASIF.AKTIF,
    });

    clear();
    getList(firmaModel);
  }, []);

  const columns: ColumnsType<KullaniciModel> = [
    {
      title: 'Kullanıcı Adı',
      dataIndex: 'kullaniciAdi',
      key: 'kullaniciAdi',
    },
    {
      title: 'Şifre',
      dataIndex: 'kullaniciSifre',
      key: 'kullaniciSifre',
    },
    {
      title: 'Ad',
      dataIndex: 'ad',
      key: 'ad',
      responsive: ['md'],
    },
    {
      title: 'Soyad',
      dataIndex: 'soyad',
      key: 'soyad',
      responsive: ['md'],
    },
    {
      title: 'Telefon',
      dataIndex: 'telefonNumarasi',
      key: 'telefonNumarasi',
      responsive: ['md'],
    },
    {
      title: 'Kullanıcı Tipi',
      dataIndex: 'kullaniciTipi',
      key: 'kullaniciTipi',
      responsive: ['md'],
    },
    {
      title: 'Durum',
      dataIndex: 'aktifPasif',
      key: 'aktifPasif',
      width: sm ? 100 : 70,
      render: (_: any, record: KullaniciModel) => <DictTableStatus record={record} />,
    },
    {
      title: 'İşlemler',
      key: 'actions',
      width: sm ? 240 : 120,
      render: (_: any, record: KullaniciModel) => <DictTableActions record={record} remove={remove} fill={fill} />,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <Collapse accordion defaultActiveKey="1">
        <Collapse.Panel header={<span className="text-base font-semibold ">Yeni Kullanıcı Tanımalama</span>} key="1">
          <div className="grid grid-cols-[100px_1fr] items-center gap-y-6 gap-x-4 sm:max-w-3xl sm:grid sm:grid-cols-[100px_1fr_100px_1fr] sm:grid-flow-row">
            <label className="whitespace-nowrap">Kullanıcı Adı :</label>
            <Input ascii form={form} name="kullaniciAdi" disabled={Boolean(selected)} />

            <label className="whitespace-nowrap">Şifre :</label>
            <Input form={form} name="kullaniciSifre" />

            <label className="whitespace-nowrap">Ad :</label>
            <Input form={form} name="ad" />

            <label className="whitespace-nowrap">Soyad :</label>
            <Input form={form} name="soyad" />

            <label className="whitespace-nowrap">Telefon :</label>
            <Input form={form} name="telefonNumarasi" />

            <label className="whitespace-nowrap">Kullanıcı Tipi :</label>
            <Select
              disabled={Boolean(selected)}
              options={[
                { label: 'Depocu', value: 'ROLE_DEPOCU' },
                { label: 'Tedarikçi', value: 'ROLE_TEDARIKCI' },
              ]}
              fieldNames={{ label: 'label', value: 'value' }}
              form={form}
              name="kullaniciTipi"
            />

            <DictFormActions selected={selected} save={save} clear={clear} className="sm:col-span-4 sm:justify-end" />
          </div>
        </Collapse.Panel>
      </Collapse>

      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100svh - 190px)' }}
        pagination={false}
        rowKey="kullaniciId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
      />
    </div>
  );
};

User.path = 'user';
User.title = 'Kullanıcı';
User.group = 'dicts';
User.roles = [ROL.ADMIN];
User.icon = <UserOutlined />;
