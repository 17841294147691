import { CloudUploadOutlined, FieldTimeOutlined, RetweetOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Select as SelectAnt, Table, Upload, UploadFile, UploadProps } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import * as API from '../../api';
import { DraggableModal, Toolbar, ToolbarLeft, ToolbarRight } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useSingleSelect, useWindowSize } from '../../hooks';
import { ROL } from '../../types';
import { createOrderColumnsBillStep1 } from '../../utils';

export const BillStep1 = () => {
  const kullaniciModel = useContext(UserContext);
  const { sm } = useWindowSize();

  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(kullaniciModel.kullaniciTipi === ROL.ADMIN ? undefined : kullaniciModel.kullaniciId);

  const [users = [], _, getUsers] = API.USERS.useFetchTedarikci({});
  const [list = [], loading, _getList] = API.ORDERS.useFetchAlisFaturasiBekleyenler({});
  const { selected, setSelected, onSelectClick } = useSingleSelect('siparisId', list);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState<UploadFile>();

  const getList = useCallback(async () => {
    if (selectedUserId) {
      _getList({
        firmaModel: kullaniciModel.firmaModel,
        kullaniciModel: {
          kullaniciId: selectedUserId,
        }
      });
    }
  }, [selectedUserId]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = useCallback(async () => {
    if (file && selected?.siparisId) {
      await API.FILES.uploadAlisFatura(file, selected.siparisId);

      getList();
      handleCancel();
    }
  }, [selected, file]);

  const handleCancel = useCallback(() => {
    setFile(undefined);
    setIsModalOpen(false);
  }, []);

  useEffect(() => {
    if (kullaniciModel.kullaniciTipi === ROL.ADMIN) {
      getUsers(kullaniciModel.firmaModel);
    }
  }, []);

  useEffect(() => {
    if (selectedUserId) {
      getList();
    }
  }, [selectedUserId]);

  const columns = createOrderColumnsBillStep1();

  const uploadProps: UploadProps = {
    onRemove: () => {
      setFile(undefined);
    },
    beforeUpload: (file) => {
      setFile(file);

      return false;
    },
    fileList: file ? [file] : [],
  };

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft>
          <Button onClick={getList} icon={<RetweetOutlined />}>
            Yenile
          </Button>
          {kullaniciModel.kullaniciTipi === ROL.ADMIN && (
            <SelectAnt
              className="w-full sm:w-64 md:max-w-[300px]"
              showSearch
              placeholder="Seçiniz"
              options={users}
              fieldNames={{ value: 'kullaniciId', label: 'kullaniciAdi' }}
              filterOption={(input, option) => (option?.kullaniciAdi ?? '').toLowerCase().includes(input.toLowerCase())}
              allowClear
              onChange={setSelectedUserId}
            ></SelectAnt>
          )}
        </ToolbarLeft>
        <ToolbarRight classname="sm:flex-none">
          <Button className="w-full" type="primary" disabled={!Boolean(selected)} onClick={() => showModal()} icon={<CloudUploadOutlined />}>
            Fatura Yükle
          </Button>
        </ToolbarRight>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={false}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
        onRow={(record) => ({
          onClick: () => setSelected(record),
        })}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: selected?.siparisId ? [selected.siparisId] : [],
          onChange: onSelectClick,
        }}
      />

      <DraggableModal width={400} title="Fatura Yükleme" okText="Kaydet" cancelText="Kapat" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="flex flex-col">
          <Upload {...uploadProps}>
            <Button block icon={<UploadOutlined />}>
              Fatura Seç
            </Button>
          </Upload>
        </div>
      </DraggableModal>
    </div>
  );
};

BillStep1.path = 'bill-step-1';
BillStep1.title = 'Fatura Bekleyenler';
BillStep1.group = 'bills';
BillStep1.roles = [ROL.ADMIN, ROL.TEDARIKCI];
BillStep1.icon = <FieldTimeOutlined />;
