import { CheckCircleOutlined, LikeOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Select as SelectAnt } from 'antd';
import React, { useCallback, useState } from 'react';
import { z } from 'zod';

import * as API from '../../api';
import { DatePicker, DraggableModal, Input, Select, Toolbar, ToolbarLeft, ToolbarRight } from '../../components';
import { useForm, useOrderList, useWindowSize } from '../../hooks';
import { ROL, SiparisModel, SIPARIS_STATU } from '../../types';

const CargoForm = z.object({
  kargoFirmasiId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  kargoNo: z.string().optional(),
  siparisKargoTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
});

export const OrderListStep2 = () => {
  const { sm } = useWindowSize();

  const [cargos = []] = API.CARGOS.useFetch({ init: true });
  const { users = [], setSelectedUserId, isAdmin, list, loading, getList, columns, selected, setSelected, onSelectClick, sipKargo } = useOrderList(SIPARIS_STATU.SIP_ONAY);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useForm(CargoForm, { kargoFirmasiId: undefined, kargoNo: '', siparisKargoTarihi: new Date() });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = useCallback(async () => {
    const { data, success } = form.parse();
    if (success) {
      const postOrder = {
        ...selected,
        alisKargoFirmasiModel: {
          kargoFirmasiId: data.kargoFirmasiId,
        },
        kargoNo: data.kargoNo,
        siparisKargoTarihi: data.siparisKargoTarihi.toISOString(),
      } as SiparisModel;

      await sipKargo(postOrder);

      handleCancel();
    } else {
      form.setAllTouched();
    }
  }, [form, selected, setIsModalOpen]);

  const handleCancel = useCallback(() => {
    form.reset();
    setIsModalOpen(false);
  }, [form, setIsModalOpen]);

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft classname="sm:flex-none">
          <Button onClick={() => getList()} icon={<RetweetOutlined />}>
            Yenile
          </Button>
          {isAdmin && (
            <SelectAnt
              className="w-full sm:w-64 md:max-w-[300px]"
              showSearch
              placeholder="Seçiniz"
              options={users}
              fieldNames={{ value: 'kullaniciId', label: 'kullaniciAdi' }}
              filterOption={(input, option) => (option?.kullaniciAdi ?? '').toLowerCase().includes(input.toLowerCase())}
              allowClear
              onChange={setSelectedUserId}
            ></SelectAnt>
          )}
        </ToolbarLeft>

        <ToolbarRight classname="sm:flex-none">
          <Button className="w-full" type="primary" disabled={!Boolean(selected)} onClick={() => showModal()} icon={<CheckCircleOutlined />}>
            Kargolandı
          </Button>
        </ToolbarRight>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={false}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
        onRow={(record) => ({
          onClick: () => setSelected(record),
        })}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: selected?.siparisId ? [selected.siparisId] : [],
          onChange: onSelectClick,
        }}
      />

      <DraggableModal width={300} title="Sipariş Kargo" okText="Kaydet" cancelText="Kapat" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="flex flex-col">
          <label className="whitespace-nowrap">Kargo Firması :</label>
          <Select options={cargos} fieldNames={{ label: 'kargoFirmasiAdi', value: 'kargoFirmasiId' }} form={form} name="kargoFirmasiId" />

          <label className="whitespace-nowrap">Kargo No</label>
          <Input form={form} name="kargoNo" />

          <label className="whitespace-nowrap">Kargo Tarihi :</label>
          <DatePicker form={form} name="siparisKargoTarihi" />
        </div>
      </DraggableModal>
    </div>
  );
};

OrderListStep2.path = 'order-list-step-2';
OrderListStep2.title = 'Onaylananlar';
OrderListStep2.group = 'supplier';
OrderListStep2.roles = [ROL.TEDARIKCI, ROL.ADMIN];
OrderListStep2.icon = <LikeOutlined />;
