import { useFetch } from '../hooks';
import { DepoyeriModel, UseFetchOptions } from '../types';
import { request } from '../utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<DepoyeriModel[]>('/depoyeri/list-by-firma', { method: 'POST', ...config }),
  useFetchActive: (config: UseFetchOptions) => useFetch<DepoyeriModel[]>('/depoyeri/aktif-list-by-firma', { method: 'POST', ...config }),
  save: async (market: DepoyeriModel) => request('/depoyeri/kayit', 'POST', market),
  update: async (market: DepoyeriModel) => request('/depoyeri/guncelle', 'POST', market),
  activePassive: async (market: DepoyeriModel) => request('/depoyeri/aktif-pasif', 'POST', market),
};
