import { DropboxOutlined, RetweetOutlined, ShoppingOutlined } from '@ant-design/icons';
import { Button, Modal, Table } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { z } from 'zod';

import * as API from '../../api';
import { DatePicker, DraggableModal, Input, InputNumber, Select, Toolbar, ToolbarLeft, ToolbarRight } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useForm, useSingleSelect, useWindowSize } from '../../hooks';
import { ROL, SiparisModel } from '../../types';
import { createOrderColumnsSalesStep3 } from '../../utils';

const FilterForm = z.object({
  pazaryeriId: z.number().optional(),
  urunId: z.number().optional(),
});

const SatisForm = z.object({
  satisFiyat: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  satisTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
});

export const SalesStep3 = () => {
  const kullaniciModel = useContext(UserContext);
  const { sm } = useWindowSize();

  const fetchModel = {
    firmaModel: kullaniciModel.firmaModel,
    kullaniciModel,
  } as SiparisModel;

  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [markets = []] = API.MARKETS.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [list = [], loading, getList] = API.ORDERS.useFetchSatis({});
  const { selected, setSelected, onSelectClick } = useSingleSelect('siparisId', list);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useForm(SatisForm, {
    satisFiyat: undefined,
    satisTarihi: new Date(),
  });

  const formFilter = useForm(FilterForm, {
    pazaryeriId: undefined,
    urunId: undefined,
  });

  const get = useCallback(() => {
    const { data, error } = formFilter.parse();
    getList({
      ...fetchModel,
      satisPazaryeriModel: {
        pazaryeriId: data.pazaryeriId,
      },
      urunModel: {
        urunId: data.urunId,
      },
    });
  }, [formFilter]);

  const handleOk = useCallback(async () => {
    const { data, success } = form.parse();
    if (success) {
      const postOrder = {
        ...selected,
        satisFiyat: data.satisFiyat,
        satisTarihi: data.satisTarihi.toISOString(),
      } as SiparisModel;

      await API.ORDERS.sipSatis(postOrder);

      get();
      handleCancel();
    }
  }, [form, selected, setIsModalOpen]);

  const handleCancel = useCallback(() => {
    form.reset();
    setIsModalOpen(false);
  }, [form, setIsModalOpen]);

  useEffect(() => {
    get()
  }, []);

  const columns = createOrderColumnsSalesStep3(get);

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft>
          <label className="whitespace-nowrap">Satış Pazaryeri :</label>
          <Select className="w-full sm:w-40" options={markets} fieldNames={{ label: 'pazaryeriAdi', value: 'pazaryeriId' }} form={formFilter} name="pazaryeriId" />

          <label className="whitespace-nowrap">Ürün :</label>
          <Select className="sm:w-72" options={products} fieldNames={{ label: 'urunAdi', value: 'urunId' }} form={formFilter} searchFields={['barkodNo']} placeholder="Ürün Adı veya Barkod" name="urunId" />

          <Button onClick={() => get()} icon={<RetweetOutlined />}>
            Listele
          </Button>
        </ToolbarLeft>
        <ToolbarRight>
          <Button type="primary" disabled={!Boolean(selected)} onClick={() => setIsModalOpen(true)} icon={<RetweetOutlined />}>
            Satış
          </Button>
        </ToolbarRight>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={false}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
        onRow={(record) => ({
          onClick: () => setSelected(record),
        })}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: selected?.siparisId ? [selected.siparisId] : [],
          onChange: onSelectClick,
        }}
      />

      <DraggableModal width={300} title="Satış" okText="Kaydet" cancelText="Kapat" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="flex flex-col">
          <label className="whitespace-nowrap">Satış Fiyat :</label>
          <InputNumber form={form} name="satisFiyat" min={1} />

          <label className="whitespace-nowrap">Satış Tarihi :</label>
          <DatePicker form={form} name="satisTarihi" />
        </div>
      </DraggableModal>
    </div>
  );
};

SalesStep3.path = 'sales-step-3';
SalesStep3.title = 'Satış';
SalesStep3.group = 'sales';
SalesStep3.roles = [ROL.ADMIN, ROL.DEPOCU];
SalesStep3.icon = <ShoppingOutlined />;
