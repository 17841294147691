import { ArrowUpOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Space } from 'antd';
import React from 'react';

type Props = {
  selected?: any;
  save: CallableFunction;
  clear: CallableFunction;
  className?: string;
};

export const DictFormActions = ({ selected, save, clear, className }: Props) => {
  return (
    <div className={`grid grid-cols-2 col-span-2 sm:flex sm:flex-row-reverse gap-2 ${className}`}>
      <Button htmlType="button" onClick={() => clear()}>
        Temizle
      </Button>
      <Button type="primary" onClick={() => save()} icon={selected ? <ArrowUpOutlined /> : <PlusCircleOutlined />}>
        {selected ? 'Güncelle' : 'Kaydet'}
      </Button>
    </div>
  );
};
