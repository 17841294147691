import { ColumnsType } from 'antd/es/table';
import { useCallback, useContext, useEffect, useState } from 'react';

import * as API from '../api';
import { UserContext } from '../contexts/UserContext';
import { AKTIF_PASIF, ROL, SiparisModel, SIPARIS_STATU } from '../types';
import { createOrderColumns } from '../utils';
import { useMultiSelect } from './useMultiSelect';
import { useSingleSelect } from './useSingleSelect';

export const useOrderList = (statu: SIPARIS_STATU) => {
  const kullaniciModel = useContext(UserContext);

  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(kullaniciModel.kullaniciTipi === ROL.ADMIN ? undefined : kullaniciModel.kullaniciId);

  const [users = [], _, getUsers] = API.USERS.useFetchTedarikci({});
  const [list = [], loading, _getList] = API.ORDERS.useFetchStatu({});
  const { selected, setSelected, onSelectClick } = useSingleSelect('siparisId', list);
  const { selectedKeyList, selectedList, onMultiSelectClick } = useMultiSelect('siparisId', list);

  const columns: ColumnsType<SiparisModel> = createOrderColumns(statu, kullaniciModel.kullaniciTipi === ROL.ADMIN);

  const getList = useCallback(async () => {
    _getList({
      siparisStatu: statu,
      firmaModel: kullaniciModel.firmaModel,
      kullaniciModel: {
        kullaniciId: selectedUserId,
      },
    });
  }, [statu, selectedUserId]);

  const activePassive = useCallback(
    async (order?: SiparisModel) => {
      if (order) {
        const postOrder = {
          siparisId: order.siparisId,
          aktifPasif: order.aktifPasif === AKTIF_PASIF.AKTIF ? AKTIF_PASIF.PASIF : AKTIF_PASIF.AKTIF,
        } as SiparisModel;

        await API.ORDERS.activePassive(postOrder);
        getList();
      }
    },
    [getList]
  );

  const activePassiveToplu = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const postOrders = orders.map(
          (order) =>
            ({
              siparisId: order.siparisId,
              aktifPasif: order.aktifPasif === AKTIF_PASIF.AKTIF ? AKTIF_PASIF.PASIF : AKTIF_PASIF.AKTIF,
            } as SiparisModel)
        );

        await API.ORDERS.activePassiveToplu(postOrders);
        getList();
      }
    },
    [getList]
  );

  const sipOnay = useCallback(
    async (order?: SiparisModel) => {
      if (order) {
        await API.ORDERS.sipOnay(order);
        getList();
      }
    },
    [getList]
  );

  const sipOnayToplu = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders && orders.length > 0) {
        console.log('X', orders);
        await API.ORDERS.sipOnayToplu(orders);
        getList();
      }
    },
    [getList]
  );

  const sipKargo = useCallback(
    async (order?: SiparisModel) => {
      if (order) {
        await API.ORDERS.sipKargo(order);
        getList();
      }
    },
    [getList]
  );

  useEffect(() => {
    if (kullaniciModel.kullaniciTipi === ROL.ADMIN) {
      getUsers(kullaniciModel.firmaModel);
    }
  }, []);

  useEffect(() => {
    getList();
  }, [selectedUserId]);

  return {
    users,
    setSelectedUserId,
    list,
    loading,
    getList,
    columns,
    selected,
    setSelected,
    onSelectClick,
    activePassive,
    activePassiveToplu,
    sipOnay,
    sipOnayToplu,
    sipKargo,
    selectedKeyList,
    selectedList,
    onMultiSelectClick,
    isAdmin: kullaniciModel.kullaniciTipi === ROL.ADMIN,
  };
};
