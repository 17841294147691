import { CloudUploadOutlined, FieldTimeOutlined, FileTextOutlined, RetweetOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Upload, UploadFile, UploadProps } from 'antd';
import React, { useCallback, useContext, useState } from 'react';

import * as API from '../../api';
import { DraggableModal, Toolbar, ToolbarLeft, ToolbarRight } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useSingleSelect, useWindowSize } from '../../hooks';
import { ROL, SIPARIS_STATU } from '../../types';
import { createOrderColumns } from '../../utils';

export const BillStep3 = () => {
  const kullaniciModel = useContext(UserContext);
  const { sm } = useWindowSize();

  const [list = [], loading, getList] = API.ORDERS.useFetchSatisFaturasiBekleyenler({ init: true, initBody: kullaniciModel.firmaModel });
  const { selected, setSelected, onSelectClick } = useSingleSelect('siparisId', list);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState<UploadFile>();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = useCallback(async () => {
    if (file && selected?.siparisId) {
      await API.FILES.uploadSatisFatura(file, selected.siparisId);

      handleCancel();

      getList(kullaniciModel.firmaModel);
    }
  }, [selected, file]);

  const handleCancel = useCallback(() => {
    setFile(undefined);
    setIsModalOpen(false);
  }, []);

  const columns = createOrderColumns(SIPARIS_STATU.SIP_KARGO, kullaniciModel.kullaniciTipi === ROL.ADMIN);

  const uploadProps: UploadProps = {
    onRemove: () => {
      setFile(undefined);
    },
    beforeUpload: (file) => {
      setFile(file);

      return false;
    },
    fileList: file ? [file] : [],
  };

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft>
          <Button onClick={() => getList(kullaniciModel.firmaModel)} icon={<RetweetOutlined />}>
            Yenile
          </Button>
        </ToolbarLeft>
        <ToolbarRight classname="sm:flex-none">
          <Button className="w-full" type="primary" disabled={!Boolean(selected)} onClick={() => showModal()} icon={<CloudUploadOutlined />}>
            Fatura Yükle
          </Button>
        </ToolbarRight>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={false}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
        onRow={(record) => ({
          onClick: () => setSelected(record),
        })}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: selected?.siparisId ? [selected.siparisId] : [],
          onChange: onSelectClick,
        }}
      />

      <DraggableModal width={400} title="Fatura Yükleme" okText="Kaydet" cancelText="Kapat" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="flex flex-col">
          <Upload {...uploadProps}>
            <Button block icon={<UploadOutlined />}>
              Fatura Seç
            </Button>
          </Upload>
        </div>
      </DraggableModal>
    </div>
  );
};

BillStep3.path = 'bill-step-3';
BillStep3.title = 'Satış Fatura';
BillStep3.group = 'bills';
BillStep3.roles = [ROL.ADMIN, ROL.DEPOCU];
BillStep3.icon = <FileTextOutlined />;
