import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { OrderDetailButton } from '../components/OrderDetailButton';
import { EVET_HAYIR, SiparisModel, SIPARIS_STATU } from '../types';
import { dateFormat, moneyColumnRender, yesNoRender } from './column';
import { CargoUrl } from '../components/CargoUrl';
import { Button } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';

export const createOrderColumns = (statu: SIPARIS_STATU, isAdmin : boolean): ColumnsType<SiparisModel> => {
  const columns: ColumnsType<SiparisModel> = [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
    },
    {
      title: 'Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['pazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Sipariş No',
      key: 'siparisNo',
      dataIndex: 'siparisNo',
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Açıklama',
      key: 'tedarikciAciklama',
      dataIndex: 'tedarikciAciklama',
    },
  ];

  if (isAdmin) {
    columns.splice(
      1,
      0,
      {
        title: 'Tedarikçi',
        key: 'tedarikci',
        width: 220,
        render: (_, record) => `${record.kullaniciModel?.kullaniciAdi} - ${record.kullaniciModel?.ad} ${record.kullaniciModel?.soyad}`,
      },
    );
  }

  if (statu === SIPARIS_STATU.SIP_KARGO) {
    columns.splice(
      5,
      0,
      {
        title: 'Kargo Firması',
        key: 'alisKargoFirmasiModel',
        dataIndex: ['alisKargoFirmasiModel', 'kargoFirmasiAdi'],
      },
      {
        title: 'Kargo No',
        key: 'kargoNo',
        dataIndex: 'kargoNo',
        width: 150,
        render: (_, order) => <CargoUrl cargoId={order?.alisKargoFirmasiModel?.kargoFirmasiId} cargoNo={order?.kargoNo} />,
      }
    );
  }

  return columns;
};

export const createOrderColumnsPaymentStep1 = (): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
    },
    {
      title: 'Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['pazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Sipariş No',
      key: 'siparisNo',
      dataIndex: 'siparisNo',
    },
    {
      title: 'Sipariş Tarihi',
      key: 'siparisTarihi',
      dataIndex: 'siparisTarihi',
      render: dateFormat,
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenecek Fiyat',
      key: 'tedarikciOdenecekFiyat',
      dataIndex: 'tedarikciOdenecekFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenecek Tarih',
      key: 'tedarikciOdenecekTarih',
      dataIndex: 'tedarikciOdenecekTarih',
      render: dateFormat,
    },
    {
      title: 'Açıklama',
      key: 'tedarikciAciklama',
      dataIndex: 'tedarikciAciklama',
    },
  ];
};

export const createOrderColumnsPaymentStep2 = (): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
    },
    {
      title: 'Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['pazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Sipariş No',
      key: 'siparisNo',
      dataIndex: 'siparisNo',
    },
    {
      title: 'Sipariş Tarihi',
      key: 'siparisTarihi',
      dataIndex: 'siparisTarihi',
      render: dateFormat,
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenecek Fiyat',
      key: 'tedarikciOdenecekFiyat',
      dataIndex: 'tedarikciOdenecekFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenecek Tarih',
      key: 'tedarikciOdenecekTarih',
      dataIndex: 'tedarikciOdenecekTarih',
      render: dateFormat,
    },
    {
      title: 'Ödenenen Fiyat',
      key: 'tedarikciOdenenFiyat',
      dataIndex: 'tedarikciOdenenFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenenen Tarih',
      key: 'tedarikciOdenenTarih',
      dataIndex: 'tedarikciOdenenTarih',
      render: dateFormat,
    },
    {
      title: 'Açıklama',
      key: 'tedarikciAciklama',
      dataIndex: 'tedarikciAciklama',
    },
  ];
};

export const createOrderColumnsSalesStep1 = (onUpdateDone: CallableFunction): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Tedarikçi',
      key: 'tedarikci',
      width: 220,
      render: (_, record) => `${record.kullaniciModel?.kullaniciAdi} - ${record.kullaniciModel?.ad} ${record.kullaniciModel?.soyad}`,
    },
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
      render: (_, record) => <OrderDetailButton order={record} onUpdateDone={onUpdateDone} />,
    },
    {
      title: 'Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['pazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Barkod',
      key: 'barkod',
      dataIndex: ['urunModel', 'barkodNo'],
    },
    {
      title: 'Sipariş No',
      key: 'siparisNo',
      dataIndex: 'siparisNo',
    },
    {
      title: 'Kargo Firması',
      key: 'alisKargoFirmasiModel',
      dataIndex: ['alisKargoFirmasiModel', 'kargoFirmasiAdi'],
    },
    {
      title: 'Kargo No',
      key: 'kargoNo',
      dataIndex: 'kargoNo',
      width: 150,
      render: (_, order) => <CargoUrl cargoId={order?.alisKargoFirmasiModel?.kargoFirmasiId} cargoNo={order?.kargoNo} />,
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Fatura Onay',
      key: 'alisFaturasiOnaylandi',
      dataIndex: 'alisFaturasiOnaylandi',
      align: 'center',
      render: yesNoRender,
    },
    {
      title: 'Açıklama',
      key: 'tedarikciAciklama',
      dataIndex: 'tedarikciAciklama',
    },
  ];
};

export const createOrderColumnsSalesStep2 = (onUpdateDone: CallableFunction): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
      render: (_, record) => <OrderDetailButton order={record} onUpdateDone={onUpdateDone} />,
    },
    {
      title: 'Depoyeri',
      key: 'depoyeriAdi',
      dataIndex: ['depoyeriModel', 'depoyeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Barkod',
      key: 'barkod',
      dataIndex: ['urunModel', 'barkodNo'],
    },
    {
      title: 'Seri No / Imei No',
      key: 'seriNo',
      dataIndex: 'seriNo',
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenecek Fiyat',
      key: 'tedarikciOdenecekFiyat',
      dataIndex: 'tedarikciOdenecekFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenenen Fiyat',
      key: 'tedarikciOdenenFiyat',
      dataIndex: 'tedarikciOdenenFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Fatura Onay',
      key: 'alisFaturasiOnaylandi',
      dataIndex: 'alisFaturasiOnaylandi',
      align: 'center',
      render: yesNoRender,
    },
    {
      title: 'Depo Giriş Tarihi',
      key: 'siparisDepoGirisTarihi',
      dataIndex: 'siparisDepoGirisTarihi',
      render: dateFormat,
    },
  ];
};

export const createOrderColumnsSalesStep3 = (onUpdateDone: CallableFunction): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
      render: (_, record) => <OrderDetailButton order={record} onUpdateDone={onUpdateDone} />,
    },
    {
      title: 'Depoyeri',
      key: 'depoyeriModel',
      dataIndex: ['depoyeriModel', 'depoyeriAdi'],
    },
    {
      title: 'Satış Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['satisPazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Barkod',
      key: 'barkod',
      dataIndex: ['urunModel', 'barkodNo'],
    },
    {
      title: 'Seri No / Imei No',
      key: 'seriNo',
      dataIndex: 'seriNo',
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenecek Fiyat',
      key: 'tedarikciOdenecekFiyat',
      dataIndex: 'tedarikciOdenecekFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenenen Fiyat',
      key: 'tedarikciOdenenFiyat',
      dataIndex: 'tedarikciOdenenenFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Depo Giriş Tarihi',
      key: 'siparisDepoGirisTarihi',
      dataIndex: 'siparisDepoGirisTarihi',
      render: dateFormat,
    },
  ];
};

export const createOrderColumnsSalesStep4 = (onUpdateDone: CallableFunction): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
      render: (_, record) => <OrderDetailButton order={record} onUpdateDone={onUpdateDone} />,
    },
    {
      title: 'Depoyeri',
      key: 'depoyeriModel',
      dataIndex: ['depoyeriModel', 'depoyeriAdi'],
    },
    {
      title: 'Satış Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['satisPazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Seri No / Imei No',
      key: 'seriNo',
      dataIndex: 'seriNo',
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Satış Fiyat',
      key: 'satisFiyat',
      dataIndex: 'satisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Satış Tarihi',
      key: 'satisTarihi',
      dataIndex: 'satisTarihi',
      align: 'center',
      render: dateFormat,
    },
  ];
};

export const createOrderColumnsSalesStep5 = (onUpdateDone: CallableFunction): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
      render: (_, record) => <OrderDetailButton order={record} onUpdateDone={onUpdateDone} />,
    },
    {
      title: 'Depoyeri',
      key: 'depoyeriModel',
      dataIndex: ['depoyeriModel', 'depoyeriAdi'],
    },
    {
      title: 'Satış Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['satisPazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Seri No / Imei No',
      key: 'seriNo',
      dataIndex: 'seriNo',
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Satış Fiyat',
      key: 'satisFiyat',
      dataIndex: 'satisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Satış Tarihi',
      key: 'satisTarihi',
      dataIndex: 'satisTarihi',
      align: 'center',
      render: dateFormat,
    },
    {
      title: 'Kargo Tarihi',
      key: 'satisKargoTarihi',
      dataIndex: 'satisKargoTarihi',
      align: 'center',
      render: dateFormat,
    },
  ];
};

export const createOrderColumnsUserPayment = (onUpdateDone: CallableFunction): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
      render: (_, record) => <OrderDetailButton order={record} onUpdateDone={onUpdateDone} />,
    },
    {
      title: 'Tedarikçi',
      key: 'tedarikci',
      width: 220,
      render: (_, record) => `${record.kullaniciModel?.kullaniciAdi} - ${record.kullaniciModel?.ad} ${record.kullaniciModel?.soyad}`,
    },
    {
      title: 'Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['pazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Sipariş No',
      key: 'siparisNo',
      dataIndex: 'siparisNo',
    },
    {
      title: 'Sipariş Tarihi',
      key: 'siparisTarihi',
      dataIndex: 'siparisTarihi',
      render: dateFormat,
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenecek Fiyat',
      key: 'tedarikciOdenecekFiyat',
      dataIndex: 'tedarikciOdenecekFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenecek Tarih',
      key: 'tedarikciOdenecekTarih',
      dataIndex: 'tedarikciOdenecekTarih',
      render: dateFormat,
    },
    {
      title: 'Açıklama',
      key: 'tedarikciAciklama',
      dataIndex: 'tedarikciAciklama',
    },
  ];
};

export const createOrderColumnsBillStep1 = (): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
    },
    {
      title: 'Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['pazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Sipariş No',
      key: 'siparisNo',
      dataIndex: 'siparisNo',
    },
    {
      title: 'Kargo Firması',
      key: 'alisKargoFirmasiModel',
      dataIndex: ['alisKargoFirmasiModel', 'kargoFirmasiAdi'],
    },
    {
      title: 'Kargo No',
      key: 'kargoNo',
      dataIndex: 'kargoNo',
      width: 150,
      render: (_, order) => <CargoUrl cargoId={order?.alisKargoFirmasiModel?.kargoFirmasiId} cargoNo={order?.kargoNo} />,
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Açıklama',
      key: 'tedarikciAciklama',
      dataIndex: 'tedarikciAciklama',
    },
    {
      title: 'Durum',
      key: 'durum',
      dataIndex: 'durum',
      align: 'center',
      render: (_, order) => <span> {order.alisFaturasiModel && order.alisFaturasiOnaylandi === EVET_HAYIR.HAYIR ? 'Onay Bekliyor' : ''} </span>
    },
  ];
};


export const createOrderColumnsBillStep4 = (onDownload: CallableFunction): ColumnsType<SiparisModel> => {
  return [
    
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
    },
    {
      title: 'Tedarikçi',
      key: 'tedarikci',
      width: 220,
      render: (_, record) => `${record.kullaniciModel?.kullaniciAdi} - ${record.kullaniciModel?.ad} ${record.kullaniciModel?.soyad}`,
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Sipariş Tarihi',
      key: 'siparisTarihi',
      dataIndex: 'siparisTarihi',
      align: 'center',
      render: dateFormat,
    },
    {
      title: 'Fatura',
      key: 'fatura',
      dataIndex: 'fatura',
      align: 'center',
      render: (_, order) => (
        <Button className="w-full" onClick={()=>onDownload(order)} icon={<CloudDownloadOutlined />}>
          İndir
        </Button>
      ),
    },
  ];
};
