import { ClockCircleOutlined, ContainerOutlined } from '@ant-design/icons';
import { Card, Statistic, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useContext, useMemo } from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';

import * as API from '../api';
import { UserContext } from '../contexts/UserContext';
import { AdminDashboardModel } from '../types';
import { moneyColumnRender } from '../utils';
import { COLORS } from '../utils/chart';

const emptyDashboard: AdminDashboardModel = {
  faturaDashboardModel: { faturasiGelenSiparisSayisi: 0, faturasiGelmeyenSiparisSayisi: 0, faturasiOnaylanmayanSiparisSayisi: 0 },
  bekleyenOdemelerDashboardModelList: [],
  stokDashboardModelList: [],
  tedarikciGetiriDashboardModelList: [],
  tedarikciSiparisSayisiDashboardModelList: [],
};

const STATU_MAP = {
  SIP_OLUSTU: 'Oluşturulanlar',
  SIP_ONAY: 'Onaylananlar',
  SIP_KARGO: 'Kargodakiler',
  SIP_DEPO: 'Depodakiler',
  SIP_ILAN: 'İlandakiler',
  SIP_SATIS: 'Satılanlar',
  SATIS_KARGO: 'Müşteriye Kargolananlar',
};

const data01 = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
  { name: 'Group E', value: 278 },
  { name: 'Group F', value: 189 },
];

const stokListColumns = [
  {
    title: 'Durum',
    key: 'label',
    dataIndex: 'label',
  },
  {
    title: 'Adet',
    key: 'adet',
    dataIndex: 'adet',
  },
];

const bekleyenOdemelerListColumns: ColumnsType<any> = [
  {
    title: 'Tedarikçi',
    key: 'tedarikci',
    render: (_, record) => `${record.kullaniciModel?.kullaniciAdi} - ${record.kullaniciModel?.ad} ${record.kullaniciModel?.soyad}`,
  },
  {
    title: 'Ödenecek Fiyat',
    key: 'odenecekFiyat',
    dataIndex: 'odenecekFiyat',
    align: 'right',
    render: moneyColumnRender,
  },
];

const tedarikciGetiriListColumns: ColumnsType<any> = [
  {
    title: 'Tedarikçi',
    key: 'tedarikci',
    render: (_, record) => `${record.kullaniciModel?.kullaniciAdi} - ${record.kullaniciModel?.ad} ${record.kullaniciModel?.soyad}`,
  },
  {
    title: 'Getiri',
    key: 'getiri',
    dataIndex: 'getiri',
    align: 'right',
    render: moneyColumnRender,
  },
];

const tedarikciSiparisListColumns: ColumnsType<any> = [
  {
    title: 'Tedarikçi',
    key: 'tedarikci',
    render: (_, record) => `${record.kullaniciModel?.kullaniciAdi} - ${record.kullaniciModel?.ad} ${record.kullaniciModel?.soyad}`,
  },
  {
    title: 'Sipariş Sayısı',
    key: 'siparisSayisi',
    dataIndex: 'siparisSayisi',
    align: 'right',
  },
];

export const AdminHome = () => {
  const kullaniciModel = useContext(UserContext);

  const [dashboardModel, loading] = API.DASHBOARD.useFetchAdmin({ init: true, initBody: kullaniciModel.firmaModel });

  const { faturaDashboardModel, bekleyenOdemelerDashboardModelList, stokDashboardModelList, tedarikciGetiriDashboardModelList, tedarikciSiparisSayisiDashboardModelList } =
    dashboardModel || emptyDashboard;

  const stokList = useMemo(() => {
    return stokDashboardModelList.map((item) => ({
      ...item,
      label: STATU_MAP[item.siparisStatu],
    }));
  }, [stokDashboardModelList]);

  return (
    <div className="flex flex-col gap-4 flex-wrap">
      <Card title="Stok Durumu">
        <div className="flex gap-2 items-center">
          <PieChart width={500} height={400}>
            <Pie dataKey="adet" nameKey="label" isAnimationActive={true} data={stokList} cx="50%" cy="52%" outerRadius={150} fill="#8884d8" label>
              {stokList.map((entry, index) => (
                <Cell key={`cell-${entry.label}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
          <Table pagination={false} rowKey="siparisStatu" size="small" bordered showSorterTooltip={false} columns={stokListColumns} dataSource={stokList} loading={loading} />
        </div>
      </Card>
      <div className="flex gap-4">
        <Card title="Tedarikçi Getiri">
          <Table
            pagination={false}
            rowKey="siparisStatu"
            size="small"
            bordered
            showSorterTooltip={false}
            columns={tedarikciGetiriListColumns}
            dataSource={tedarikciGetiriDashboardModelList}
            loading={loading}
          />
        </Card>
        <Card title="Ödeme Bekleyenler">
          <Table
            pagination={false}
            rowKey="siparisStatu"
            size="small"
            bordered
            showSorterTooltip={false}
            columns={bekleyenOdemelerListColumns}
            dataSource={bekleyenOdemelerDashboardModelList}
            loading={loading}
          />
        </Card>

        <Card title="Tedarikçi Sipariş Sayısı">
          <Table
            pagination={false}
            rowKey="siparisStatu"
            size="small"
            bordered
            showSorterTooltip={false}
            columns={tedarikciSiparisListColumns}
            dataSource={tedarikciSiparisSayisiDashboardModelList}
            loading={loading}
          />
        </Card>

        <div className="flex flex-col gap-1.5">
        <Card className="h-24" bordered={false}>
          <Statistic title="Faturası Yüklenenler" value={faturaDashboardModel.faturasiGelenSiparisSayisi} valueStyle={{ color: '#3f8600' }} prefix={<ContainerOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic
            title="Faturası Yüklenmeyenler"
            value={faturaDashboardModel.faturasiGelmeyenSiparisSayisi}
            valueStyle={{ color: '#cf1322' }}
            prefix={<ClockCircleOutlined />}
          />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic
            title="Faturası Onay Bekleyenler"
            value={faturaDashboardModel.faturasiOnaylanmayanSiparisSayisi}
            valueStyle={{ color: '#cf1322' }}
            prefix={<ClockCircleOutlined />}
          />
        </Card>
        </div>
      </div>
    </div>
  );
};
