import { DropboxOutlined, FieldTimeOutlined, RetweetOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import React, { useContext } from 'react';

import * as API from '../../api';
import { Toolbar, ToolbarLeft } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useWindowSize } from '../../hooks';
import { EVET_HAYIR, ROL, SiparisModel } from '../../types';
import { createOrderColumnsPaymentStep1 } from '../../utils';

export const PaymentStep1 = () => {
  const kullaniciModel = useContext(UserContext);
  const { sm } = useWindowSize();

  const fetchModel = {
    firmaModel: kullaniciModel.firmaModel,
    kullaniciModel,
    odemesiYapildi: EVET_HAYIR.HAYIR,
  } as SiparisModel;

  const [list = [], loading, getList] = API.ORDERS.useFetchPayment({ init: true, initBody: fetchModel });
  const columns = createOrderColumnsPaymentStep1();

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft>
          <Button onClick={() => getList(fetchModel)} icon={<RetweetOutlined />}>
            Yenile
          </Button>
        </ToolbarLeft>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={false}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
      />
    </div>
  );
};

PaymentStep1.path = 'payment-step-1';
PaymentStep1.title = 'Ödeme Yapılacaklar';
PaymentStep1.group = 'payment';
PaymentStep1.roles = [ROL.TEDARIKCI];
PaymentStep1.icon = <FieldTimeOutlined />;
