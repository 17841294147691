import { DropboxOutlined, FieldTimeOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, Modal, Table } from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import { z } from 'zod';

import * as API from '../../api';
import { DatePicker, DraggableModal, InputNumber, Toolbar, ToolbarLeft, ToolbarRight } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useForm, useSingleSelect, useWindowSize } from '../../hooks';
import { ROL, SiparisModel } from '../../types';
import { createOrderColumnsUserPayment } from '../../utils';

const FilterForm = z.object({
  kullaniciId: z.number().optional(),
});

const PaymentForm = z.object({
  tedarikciOdenenFiyat: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  tedarikciOdenenTarih: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
});

export const UserPayment = () => {
  const kullaniciModel = useContext(UserContext);
  const { sm } = useWindowSize();

  const fetchModel = {
    firmaModel: kullaniciModel.firmaModel,
  } as SiparisModel;

  const [list = [], loading, getList] = API.ORDERS.useFetchAdminOdenecekList({ init: true, initBody: fetchModel });
  const { selected, setSelected, onSelectClick } = useSingleSelect('siparisId', list);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const form = useForm(PaymentForm, {
    tedarikciOdenenFiyat: undefined,
    tedarikciOdenenTarih: new Date(),
  });

  const formFilter = useForm(FilterForm, {
    kullaniciId: undefined,
  });

  const get = useCallback(() => {
    const { data } = formFilter.parse();
    getList({
      ...fetchModel,
      kullaniciModel: {
        kulllaniciId: data.kullaniciId,
      },
    });
  }, [formFilter]);

  const handleOk = useCallback(async () => {
    const { data, success } = form.parse();
    if (success) {
      const postOrder = {
        ...selected,
        tedarikciOdenenFiyat: data.tedarikciOdenenFiyat,
        tedarikciOdenenTarih: data.tedarikciOdenenTarih.toISOString(),
      } as SiparisModel;

      await API.ORDERS.sipTedarikciOdeme(postOrder);

      get();
      handleCancel();
    }
  }, [form, selected, setIsModalOpen]);

  const handleCancel = useCallback(() => {
    form.reset();
    setIsModalOpen(false);
  }, [form, setIsModalOpen]);

  const handleOpen = useCallback(() => {
    form.setField('tedarikciOdenenFiyat')(selected?.tedarikciOdenecekFiyat);
    form.setField('tedarikciOdenenTarih')(new Date(selected?.tedarikciOdenecekTarih as string));
    setIsModalOpen(true);
  }, [form, selected, setIsModalOpen]);

  const columns = createOrderColumnsUserPayment(get);

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft>
          <Button onClick={() => get()} icon={<RetweetOutlined />}>
            Yenile
          </Button>
        </ToolbarLeft>
        <ToolbarRight>
          <Button type="primary" disabled={!Boolean(selected)} onClick={handleOpen} icon={<RetweetOutlined />}>
            Ödeme Yap
          </Button>
        </ToolbarRight>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={false}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
        onRow={(record) => ({
          onClick: () => setSelected(record),
        })}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: selected?.siparisId ? [selected.siparisId] : [],
          onChange: onSelectClick,
        }}
      />
      <DraggableModal width={300} title="Sipariş Kargo" okText="Kaydet" cancelText="Kapat" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="flex flex-col">
          <label className="whitespace-nowrap">Ödenecek Fiyat :</label>
          <InputNumber form={form} name="tedarikciOdenenFiyat" min={1} />

          <label className="whitespace-nowrap">Ödenecek Tarih :</label>
          <DatePicker form={form} name="tedarikciOdenenTarih" />
        </div>
      </DraggableModal>
    </div>
  );
};

UserPayment.path = 'user-payment';
UserPayment.title = 'Ödeme Bekleyenler';
UserPayment.group = 'payment';
UserPayment.roles = [ROL.ADMIN];
UserPayment.icon = <FieldTimeOutlined />;
