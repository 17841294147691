import { CheckCircleOutlined, DeleteOutlined, DropboxOutlined, LikeOutlined, RetweetOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import React from 'react';

import { Toolbar, ToolbarLeft } from '../../components';
import { useOrderList, useWindowSize } from '../../hooks';
import { ROL, SIPARIS_STATU } from '../../types';

export const OrderListStep3 = () => {
  const { sm } = useWindowSize();

  const { list, loading, getList, columns } = useOrderList(SIPARIS_STATU.SIP_KARGO);

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft>
          <Button onClick={() => getList()} icon={<RetweetOutlined />}>
            Yenile
          </Button>
        </ToolbarLeft>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={false}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
      />
    </div>
  );
};

OrderListStep3.path = 'order-list-step-3';
OrderListStep3.title = 'Kargolananlar';
OrderListStep3.group = 'supplier';
OrderListStep3.roles = [ROL.TEDARIKCI];
OrderListStep3.icon = <SendOutlined />;
