import { useFetch } from '../hooks';
import { KategoriModel, UseFetchOptions } from '../types';
import { request } from '../utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<KategoriModel[]>('/kategori/list-by-firma', { method: 'POST', ...config }),
  useFetchActive: (config: UseFetchOptions) => useFetch<KategoriModel[]>('/kategori/aktif-list-by-firma', { method: 'POST', ...config }),
  save: async (category: KategoriModel) => request('/kategori/kayit', 'POST', category),
  update: async (category: KategoriModel) => request('/kategori/guncelle', 'POST', category),
  activePassive: async (category: KategoriModel) => request('/kategori/aktif-pasif', 'POST', category),
};
