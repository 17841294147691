/* eslint-disable import/prefer-default-export */
import classNames from 'classnames';
import { PresetColorTypes } from '../_util/colors';
const PresetColorRegex = new RegExp(`^(${PresetColorTypes.join('|')})(-inverse)?$`);
export function parseColor(prefixCls, color) {
  const className = classNames({
    [`${prefixCls}-${color}`]: color && PresetColorRegex.test(color)
  });
  const overlayStyle = {};
  const arrowStyle = {};
  if (color && !PresetColorRegex.test(color)) {
    overlayStyle.background = color;
    // @ts-ignore
    arrowStyle['--antd-arrow-background-color'] = color;
  }
  return {
    className,
    overlayStyle,
    arrowStyle
  };
}