import { CheckCircleOutlined, CloseOutlined, DeleteOutlined, DropboxOutlined, PlusCircleOutlined, ProfileOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, Modal, Select, Table } from 'antd';
import React, { useCallback, useState } from 'react';

import { Toolbar, ToolbarLeft, ToolbarRight } from '../../components';
import { useOrderList, useWindowSize } from '../../hooks';
import { ROL, SIPARIS_STATU } from '../../types';
import { NewOrder } from './NewOrder';

export const OrderListStep1 = () => {
  const { sm } = useWindowSize();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const {
    users = [],
    setSelectedUserId,
    isAdmin,
    list,
    loading,
    getList,
    columns,
    selectedKeyList,
    selectedList,
    onMultiSelectClick,
    activePassiveToplu,
    sipOnayToplu,
  } = useOrderList(SIPARIS_STATU.SIP_OLUSTU);

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft classname="sm:flex-none">
          <Button onClick={() => getList()} icon={<RetweetOutlined />}>
            Yenile
          </Button>
          {isAdmin && (
            <Select
              className="w-full sm:w-64 md:max-w-[300px]"
              showSearch
              placeholder="Seçiniz"
              options={users}
              fieldNames={{ value: 'kullaniciId', label: 'kullaniciAdi' }}
              filterOption={(input, option) => (option?.kullaniciAdi ?? '').toLowerCase().includes(input.toLowerCase())}
              allowClear
              onChange={setSelectedUserId}
            ></Select>
          )}
        </ToolbarLeft>

        <ToolbarRight classname="sm:flex-none">
          <Button
            className=" flex-1 sm:flex-none"
            type="primary"
            onClick={() => setIsModalOpen(true)}
            icon={<PlusCircleOutlined />}
          >
            Yeni Sipariş
          </Button>
          <Button
            className=" flex-1 sm:flex-none"
            type="primary"
            danger
            disabled={!Boolean(selectedList?.length)}
            onClick={() => activePassiveToplu(selectedList)}
            icon={<DeleteOutlined />}
          >
            Sil
          </Button>
          <Button
            className=" flex-1 sm:flex-none"
            type="primary"
            disabled={!Boolean(selectedList?.length)}
            onClick={() => sipOnayToplu(selectedList)}
            icon={<CheckCircleOutlined />}
          >
            Onaylandı
          </Button>
        </ToolbarRight>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={false}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedKeyList || [],
          onChange: onMultiSelectClick,
        }}
      />

      <Modal width={480} footer={null} cancelText="Kapat" open={isModalOpen}  onCancel={handleCancel} bodyStyle={{marginTop: 20}} >
        <NewOrder onSaveDone={() => {
          getList();
          setIsModalOpen(false);
        }} />
      </Modal>
    </div>
  );
};

OrderListStep1.path = 'order-list-step-1';
OrderListStep1.title = 'Oluşturulanlar';
OrderListStep1.group = 'supplier';
OrderListStep1.roles = [ROL.TEDARIKCI, ROL.ADMIN];
OrderListStep1.icon = <ProfileOutlined />;
